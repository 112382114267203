body {
    margin: 0; /* Αφαιρέστε το margin προκειμένου να εξασφαλίσετε ότι το φόντο καλύπτει ολόκληρη την σελίδα */
    background-color: black;
    color: white;
  }


.dashboard-container {
    background-color: black;
    color: white;
    padding: 20px;
  }
  
  .dashboard-table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 100px;
  }
  
  .dashboard-table th,
  .dashboard-table td {
    border: 1px solid #6DF1FF;
    padding: 8px;
    text-align: left;
  }

  button{
    border-radius: 20px;
    background-color: #6DF1FF;
    color: black;
    border: none;
    width: 180px;
    height: 40px;
    font-size: 15px;
    margin-left: 0px;
    
  }

  label {
    margin-right: 10px; /* Προσθήκη margin στο label για απόσταση από το input */
  }
  
  input {
    height: 25px;
    border-radius: 20px; /* Προσθήκη στρογγυλεμένων ακρών */
    border: 2px solid #6DF1FF; /* Γαλάζιο περίγραμμα */
    padding: 8px; /* Αποστάση εσωτερικού padding */
    margin-right: 30px; /* Προσθήκη margin-right για απόσταση από το κουμπί */
  }

  select {
    border-radius: 20px; /* Προσθήκη στρογγυλεμένων ακρών */
    border: 1px solid #6DF1FF; /* Γαλάζιο περίγραμμα */
    padding: 8px; /* Αποστάση εσωτερικού padding */
    margin-left: 15px; /* Προσθήκη margin-right για απόσταση από το input */
  }
  

  @media (max-width: 770px) {
    body {
        margin: 0;
        background-color: black;
        color: white;
      }
      
      .dashboard-container {
        background-color: black;
        color: white;
        padding: 20px;
      }
      
      .dashboard-table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 10px;
      }
      
      .dashboard-table th,
      .dashboard-table td {
        border: 1px solid #6DF1FF;
        padding: 8px;
        text-align: left;
      }
      
      button {
        border-radius: 20px;
        background-color: #6DF1FF;
        color: black;
        border: none;
        width: 100%;
        height: 40px;
        font-size: 15px;
        margin-top: 10px;
      }
      
      label {
        margin-right: 10px;
        margin-top: 10px;
      }
      
      input,
      select {
        width: calc(100% - 20px);
        height: 25px;
        border-radius: 20px;
        border: 2px solid #6DF1FF;
        padding: 8px;
        margin-top: 10px;
        margin-left: 10px;
      }
      
      /* Στυλ για το "Total" */
      #total-display {
        font-weight: bold;
        font-size: 18px;
        color: #6DF1FF;
        margin-top: 10px;
      }
      

  }