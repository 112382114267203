/*App.css */
@media (min-width: 768px) {
.app-container {
  background-image: url('../../assets/background.png'); /* Ορίστε τη διαδρομή στη φωτογραφία όπως απαιτείται */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

}


.startbutton {
  border-radius: 20px;
    background-color: #6DF1FF;
    color: black;
    border: none;
    width: 300px;
    height: 40px;
    font-size: 20px;
    margin-top: 700px;
    
  
}

.physicalbutton{
  width: 300px;
    height: 40px;
    font-size: 20px;
}

.dashboard-button{
  width: 300px;
  height: 40px;
  font-size: 20px;
}
.gkelakiabutton{
  width: 300px;
  height: 40px;
  font-size: 20px;
}

}

@media (max-width: 770px) {
  .app-container {
    background-image: url('../../assets/background.png'); /* Ορίστε τη διαδρομή στη φωτογραφία όπως απαιτείται */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
  }
  
  
  .startbutton {
    border-radius: 20px;
      background-color: #6DF1FF;
      color: black;
      border: none;
      width: 200px;
      height: 40px;
      font-size: 20px;
      margin-top: 700px;
    
  }

  
  }