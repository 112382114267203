 /*bet.css */
 @media (min-width: 768px) {
    .spins-container {
      background-image: url('../../assets/dorotroxos\ -06.png'); /* Ορίστε τη διαδρομή στη φωτογραφία όπως απαιτείται */
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100vh;
    
    }
    
    
    
    .spinsbutton {
      border-radius: 20px;
        background-color: #6DF1FF;
        color: black;
        border: none;
        width: 180px;
        height: 40px;
        font-size: 25px;
        margin-top: 700px;
      
    }
    }


  
 @media (max-width: 770px) {
  .spins-container {
    background-image: url('../../assets/dorotroxos\ -06.png'); /* Ορίστε τη διαδρομή στη φωτογραφία όπως απαιτείται */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  
  }
  
  
  .spinsbutton {
    border-radius: 20px;
      background-color: #6DF1FF;
      color: black;
      border: none;
      width: 180px;
      height: 40px;
      font-size: 25px;
      margin-top: 700px;
    
  }
  }