@media (min-width: 768px) {
    .data-container {
        background-image: url('../../assets/background.png'); /* Ορίστε τη διαδρομή στη φωτογραφία όπως απαιτείται */
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }



      .data-container select,
  .data-container input {
    width: 500px;
    height: 40px;
    margin-bottom: 15px;
    padding: 10px;
    border: 2px solid #6DF1FF;
    border-radius: 10px;
    font-size: 16px;
    position: relative;
    bottom: -100px;
  }

  .data-container .city-container select {
    /* Εξατομίκευση του select για την πόλη */
    background-color: white;
    appearance: none;
    width: 500px;
    left: -22px;
  }

  .data-container .city-container select::-ms-expand {
    display: none;
  }

  .data-container .gift-container select {
    /* Εξατομίκευση του select για την πόλη */
    background-color: white;
    appearance: none;
    width: 500px;
    left: -22px;
  }

  .data-container .submitbutton-container {
    text-align: center;
  }
  


  
  
  
  

  .data-container .submitbutton {
    border-radius: 20px;
    background-color: #6DF1FF;
    color: black;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
    bottom: -150px;
    width:500px;
    font-size: 20px;
    left: -20px;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    color: black; /* Προσθήκη αυτής της γραμμής για να καθορίσετε το χρώμα των γραμμάτων */
  }
  
}



@media (max-width: 770px) {
  .data-container {
      background-image: url('../../assets/background.png'); /* Ορίστε τη διαδρομή στη φωτογραφία όπως απαιτείται */
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }



    .data-container select,
.data-container input {
  width: 300px;
  margin-bottom: 1px;
  padding: 10px;
  border: 2px solid #6DF1FF;
  border-radius: 10px;
  font-size: 16px;
  position: relative;
  bottom: -100px;
  left: 10px;
}



.data-container .city-container select {
  /* Εξατομίκευση του select για την πόλη */
  background-color: white;
  appearance: none;
  width: 300px;
    left: -5px;
    padding: 0px;

}

.data-container .gift-container select {
  /* Εξατομίκευση του select για την πόλη */
  background-color: white;
  appearance: none;
  width: 300px;
    left: -5px;
    padding: 0px;
}



.data-container .city-container select::-ms-expand {
  display: none;
}

.data-container .submitbutton-container {
  text-align: center;
}








.data-container .submitbutton {
  border-radius: 20px;
  background-color: #6DF1FF;
  color: black;
  font-size: 18px;
  padding: 10px 20px;
  cursor: pointer;
  position: relative;
  bottom: -150px;
  width:300px;
  font-size: 20px;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
}






  


    